export const STARTER_TO_IMAGE = {
  CONTENTFUL: {
    Blog: `https://user-images.githubusercontent.com/18426780/61743978-7b0f4580-ad53-11e9-9b7a-38b9447c8020.png`,
  },
  DATOCMS: {
    Snipcart: `https://rawgit.com/datocms/snipcart-gatsby-demo/master/preview.png?w=600`,
    Blog: `https://gatsbycontent.wpengine.com/wp-content/uploads/2021/09/dato-cms-blog-demo.png`,
  },
  COSMICJS: {
    Blog: `https://s3-us-west-2.amazonaws.com/cosmicjs/d6276410-68c2-11e8-9105-b16ce5fe6091-Screenshot-4.png`,
  },
  NONE: {
    Blog: `https://www.gatsbyjs.com/static/0e2cc8c09f8ba56fc8577df9c589c431/85e35/3576b917c4111516bdfc044ec7465d09.webp`,
    "Gatsby Cara Portfolio Starter": `https://www.gatsbyjs.com/static/2987a3fd07e423701e10135b5d456d83/85e35/b311563c9dd5f7af0fe31e00480ee946.webp`,
    Default: `https://www.gatsbyjs.com/static/3833f4ebd008c83959677433b5672679/5803e/d1c71f638d7d35fc92aadba5fa13990c.webp`,
    "Minimal Blog": `https://www.gatsbyjs.com/static/1825bdf648dad1558d028ff5ca2a596c/85e35/d84327eef17a7116716d0ababaf20ac0.webp`,
  },
}
