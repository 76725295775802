import { siteDetails as text } from "@modules/locales/default.js"
import { SiteSettingsSection } from "../settings/constants"

export const SiteDetailsTab = {
  Builds: `builds`,
  Settings: `settings`,
  Deploys: "deploys",
  CmsPreview: `cmsPreview`,
}

export const tabsConfig = {
  [SiteDetailsTab.Settings]: {
    tab: SiteDetailsTab.Settings,
    label: text.settings,
    hasSections: true,
    initialSection: SiteSettingsSection.General,
  },
  [SiteDetailsTab.Deploys]: {
    tab: SiteDetailsTab.Deploys,
    label: text.builds,
    hasSections: false,
  },
  [SiteDetailsTab.CmsPreview]: {
    tab: SiteDetailsTab.CmsPreview,
    label: text.previews,
    hasSections: false,
  },
}
