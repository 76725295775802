import * as React from "react"
import { EmptyState, EmptyStatePrimaryAction } from "gatsby-interface"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { createSite as createSiteText } from "@modules/locales/default.js"

export type ZeroGithubInstallationsProps = {
  popInstallationWindow: (
    eventName: string,
    eventParams: { [k: string]: string }
  ) => void
  setOrgLoading: (isLoading: boolean) => void
}

export function ZeroGithubInstallations({
  popInstallationWindow,
  setOrgLoading,
}: ZeroGithubInstallationsProps) {
  return (
    <EmptyState
      graphic={<EmptyStateGraphic />}
      heading={createSiteText.headers.zeroInstallations}
      headingAs="h2"
      text=""
      primaryAction={
        <EmptyStatePrimaryAction
          onClick={() => {
            setOrgLoading(true)
            popInstallationWindow("Add New Organization", {
              uiSource: `Repository Selection - Zero State`,
            })
          }}
        >
          {createSiteText.actions.selectInstallation}
        </EmptyStatePrimaryAction>
      }
      variant="BORDERED"
    />
  )
}
