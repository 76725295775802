import * as React from "react"
import { navigate } from "gatsby"
import ProvisionSiteTabs from "./ProvisionSiteTabs"
import { Footer } from "@modules/site/create/shared/components/Footer"
import PickSourceTarget from "./PickSourceTarget"
import ConnectCms from "./ConnectCms"
import Summary from "./Summary"
import PickStarter from "@modules/onboarding/gaFlow/components/PickStarter"
import { PageContent } from "@modules/layout/components/Page"
import useProvisioningOptions from "@modules/provisioning/hooks/useProvisioningOptions"
import { SiteCreationWizardHeader } from "@modules/site/create/shared/components/SiteCreationWizardHeader"
import { ThemeCss } from "gatsby-interface"

import { getPathToCreateSite } from "@modules/site/create/shared/utils"

export type ProvisionSiteProps = {
  organizationId: string
  siteId: string
  path: string
}

function ProvisionSite({ organizationId, siteId, path }: ProvisionSiteProps) {
  const pickCMS = path.includes(`cms`)
  const summary = path.includes(`summary`)
  const { provisioningOptions } = useProvisioningOptions(organizationId)

  const getCMSProvider = () => {
    if (!provisioningOptions) {
      return ""
    }
    return provisioningOptions.cmsProvider
  }

  const getActiveStep = () => {
    if (siteId) {
      if (summary) {
        return `summary`
      }
      return `connect`
    }

    if (pickCMS) {
      return `cms`
    }

    return `organization`
  }

  return (
    <div css={rootCss}>
      <main>
        <SiteCreationWizardHeader organizationId={organizationId} />

        <ProvisionSiteTabs
          organizationId={organizationId}
          activeStep={getActiveStep()}
          path={path}
          siteId={siteId}
          cmsProvider={getCMSProvider()}
        />

        {!siteId && pickCMS && (
          <PageContent.Positioner
            css={theme => ({
              marginTop: theme.space[12],
            })}
          >
            <PickStarter organizationId={organizationId}>
              <Footer
                type="submit"
                cancel={() =>
                  navigate(`${getPathToCreateSite(organizationId)}?new_site=1`)
                }
                buttonText="Next"
                css={theme => ({
                  marginTop: theme.space[12],
                })}
              />
            </PickStarter>
          </PageContent.Positioner>
        )}
        {!siteId && !pickCMS && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore - temprarily silence TS complaiing till the moment PickSourceTarget is refacotred to TS
          <PickSourceTarget organizationId={organizationId} />
        )}
        {siteId && summary && (
          <Summary organizationId={organizationId} siteId={siteId} />
        )}
        {siteId && !summary && (
          <ConnectCms organizationId={organizationId} siteId={siteId} />
        )}
      </main>
    </div>
  )
}

export default ProvisionSite

/* styles */

const rootCss: ThemeCss = theme => ({
  padding: `${theme.space[9]} 0`,
  paddingBottom: `7.5rem`,
})
