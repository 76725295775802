/**
 * The "PickSourceTarget" component got pretty big and hairy,
 * so it's been split up into two components:
 *
 * - PickSourceTarget manages data-fetching, mutations,
 *   validation, and alternate (empty / warning / edge) states
 *
 * - PickSourceTargetContents renders the UI and handles the
 *   different variations that are at play.
 *
 * This is somewhat similar to the container/presentational
 * pattern; this component is the container.
 */
import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { graphql, useQuery } from "react-apollo"
import gql from "graphql-tag"
import { navigate } from "gatsby"
import { Form as FormikForm } from "formik"
import Form from "@modules/form/components/Form"
import * as Yup from "yup"
import { SourceControlProvider } from "@modules/graphql/types"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import { provisionSite as text } from "@modules/locales/default.js"
import { USER_INSTALLATIONS_QUERY } from "@modules/graphql/queries"
import Loading from "@modules/ui/components/Loading"
import { ZeroGithubInstallations } from "@modules/organization/shared/components/ZeroGithubInstallations"
import useInstallationWindow from "@modules/toolkit/closable-window/useInstallationWindow"
import InstallationWindowLoader from "@modules/toolkit/closable-window/InstallationWindowLoader"
import useProvisioningOptions from "@modules/provisioning/hooks/useProvisioningOptions"
import { Link } from "gatsby-interface"
import PickSourceTargetContents from "./PickSourceTargetContents"
import { sortListOfSourceTargets } from "./PickSourceTarget.helpers"
import { getPathToProvisionSite } from "@modules/site/create/shared/utils"
import {
  ErrorAlert,
  useTriggerErrorAlert,
} from "@modules/ui/components/ErrorAlert"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { Text, Spacer } from "gatsby-interface"

const validationSchema = Yup.object().shape({
  organization: Yup.string().required(
    `You must select an organization to continue`
  ),
  name: Yup.string().required(`Enter a site name`),
})

function PickSourceTarget({ mutate, organizationId }) {
  const repoNameRef = useRef("")
  const [isShowingInstallation, setShowingInstallation] = useState(false)
  const [hasClosedWindow, setHasClosedWindow] = useState(false)
  const [setError, errorAlert] = useTriggerErrorAlert()
  const { provisioningOptions } = useProvisioningOptions(organizationId)

  const { pollInterval, popInstallationWindow } = useInstallationWindow(() => {
    setHasClosedWindow(true)
    setShowingInstallation(false)
  })

  const { data, loading, error } = useQuery(USER_INSTALLATIONS_QUERY, {
    pollInterval,
  })

  const rawSourceTargets = (data && data.installationsForUser) || []
  const sourceTargets = sortListOfSourceTargets(rawSourceTargets)

  if (error) {
    return (
      <NarrowSingleColumn>
        <ErrorAlert>{error.message}</ErrorAlert>
      </NarrowSingleColumn>
    )
  }

  // Load the user installations
  if (loading) {
    return (
      <NarrowSingleColumn>
        <Loading
          delay={1000}
          message={`Loading your organizations...`}
          bufferSize="padded"
        />
      </NarrowSingleColumn>
    )
  }

  // Display a message when organisations are empty
  if (isShowingInstallation) {
    return (
      <NarrowSingleColumn>
        <InstallationWindowLoader
          message={text.openWindowGithubAuthentication}
          onPopInstallationWindow={() =>
            popInstallationWindow(text.addIt, {
              uiSource: `Organization Selection`,
            })
          }
        />
      </NarrowSingleColumn>
    )
  }

  // Window has been closed, display "wait a moment" thing
  if (hasClosedWindow && sourceTargets.length === 0) {
    return (
      <NarrowSingleColumn>
        <InstallationWindowLoader
          message={text.waitAMoment}
          onPopInstallationWindow={() =>
            popInstallationWindow(text.addIt, {
              uiSource: `Organization Selection`,
            })
          }
        />
      </NarrowSingleColumn>
    )
  }

  if (sourceTargets.length === 0) {
    return (
      <NarrowSingleColumn>
        <ZeroGithubInstallations
          popInstallationWindow={popInstallationWindow}
          setOrgLoading={setShowingInstallation}
        />
      </NarrowSingleColumn>
    )
  }

  const handleSubmit = (values, actions) => {
    return mutate({
      variables: {
        organizationId,
        provider: SourceControlProvider.Github,
        selectedInstallationId: values.organization,
        projectName: values.name,
        vendor: provisioningOptions.cmsProvider,
        templateId: provisioningOptions.starter,
      },
    })
      .then(({ data }) => {
        const site =
          data.createProvisionedSite && data.createProvisionedSite.site

        const { success, message, errorType } = data.createProvisionedSite
          ? data.createProvisionedSite.validation
          : {}

        if (site && success) {
          const basePathToProvision = getPathToProvisionSite(organizationId)

          if (provisioningOptions.cmsProvider === `NONE`) {
            return navigate(`${basePathToProvision}/${site.id}/summary`)
          }

          return navigate(`${basePathToProvision}/${site.id}/connect`)
        }
        actions.setSubmitting(false)

        if (errorType && errorType === `UPDATE_GITHUB_PERMISSIONS`) {
          const installedTarget = sourceTargets.find(
            ({ id }) => values.organization === id
          )

          return setError({
            message: (
              <p>
                {message}{" "}
                <Link
                  variant="SIMPLE"
                  href={`${installedTarget.settingsUrl}/permissions/update`}
                  target="_blank"
                >
                  {text.messages.updatePermissions}
                </Link>
              </p>
            ),
          })
        }

        return setError({ message })
      })
      .catch(err => {
        actions.setSubmitting(false)

        return setError(err)
      })
  }

  const defaultOrganization = sourceTargets[0]

  return (
    <NarrowSingleColumn>
      <WizardStepHeader title={text.configureRepository} />

      <Text>{text.messages.creatingRepository}</Text>

      <Form
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{
          name: repoNameRef.current,
          organization: defaultOrganization ? defaultOrganization.id : "",
        }}
      >
        {({ isSubmitting, values }) => {
          const currentTarget = sourceTargets.find(
            org => org.id === values.organization
          )

          if (isSubmitting) {
            return (
              <Loading message="Creating repository…" bufferSize="padded" />
            )
          }

          return (
            <React.Fragment>
              {errorAlert && (
                <React.Fragment>
                  {errorAlert}
                  <Spacer size={3} />
                </React.Fragment>
              )}
              <FormikForm>
                <PickSourceTargetContents
                  organizationId={organizationId}
                  sourceTargets={sourceTargets}
                  currentSourceTarget={currentTarget}
                  repoName={values.name}
                  onAddOrganization={() => {
                    setShowingInstallation(true)
                    repoNameRef.current = values.name

                    popInstallationWindow(text.addIt, {
                      uiSource: `Repository Selection`,
                    })
                  }}
                />
              </FormikForm>
            </React.Fragment>
          )
        }}
      </Form>
    </NarrowSingleColumn>
  )
}

PickSourceTarget.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const createProvisionedSite = gql`
  mutation createProvisionedSite(
    $organizationId: UUID
    $provider: SourceControlProvider
    $selectedInstallationId: String!
    $projectName: String!
    $vendor: CmsVendor!
    $templateId: String!
  ) {
    createProvisionedSite(
      organizationId: $organizationId
      selectedInstallationId: $selectedInstallationId
      projectName: $projectName
      vendor: $vendor
      provider: $provider
      templateId: $templateId
    ) {
      site {
        id
      }
      validation {
        success
        message
        errorType
      }
    }
  }
`

export default graphql(createProvisionedSite)(PickSourceTarget)
