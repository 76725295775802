import * as React from "react"
import { MdArrowForward } from "react-icons/md"
import { Button } from "gatsby-interface"
import { createSite as text } from "@modules/locales/default.js"
import { ThemeCss } from "gatsby-interface"

const buttonSetCss: ThemeCss = theme => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",

  margin: `${theme.space[4]} 1rem`,
  maxWidth: "46rem",

  [theme.mediaQueries.phablet]: {
    margin: `${theme.space[8]} auto`,
  },
})

const footerCss: ThemeCss = theme => ({
  background: theme.colors.white,
  borderTop: `1px solid ${theme.colors.grey[20]}`,
  position: "fixed",
  zIndex: 2,
  left: 0,
  bottom: 0,
  width: "100%",

  [theme.mediaQueries.phablet]: {
    padding: "0 1rem",
  },
})

export type FooterProps = {
  cancel: () => void
  submitForm?: () => void
  isSubmitting?: boolean
  type?: `button` | `submit` | `reset`
  useCancelLabel?: boolean
  disabled?: boolean
  buttonText?: string
}

export function Footer({
  cancel,
  submitForm,
  buttonText,
  isSubmitting = false,
  type = `button`,
  useCancelLabel = false,
  disabled = false,
  ...rest
}: FooterProps) {
  return (
    <footer {...rest} css={footerCss}>
      <div css={buttonSetCss}>
        <Button
          variant={`SECONDARY`}
          type="button"
          onClick={cancel}
          data-cy="footer-cancel-button"
        >
          {useCancelLabel ? text.cancel : text.goBack}
        </Button>
        <Button
          variant={`PRIMARY`}
          type={type}
          disabled={disabled || isSubmitting || false}
          loading={isSubmitting}
          onClick={submitForm && submitForm}
          data-cy="create-site-button"
          rightIcon={<MdArrowForward />}
        >
          {buttonText}
        </Button>
      </div>
    </footer>
  )
}
